<div class="content">
  <div class="service"> <!--SOFTWARE INSTRUCTION-->
    <h3>software instruction</h3>
    <p>GPTS partners combine for over 50 years of instruction in both practical applications and technical software including CAD and mapping applications, office software, photography and video editing as well as custom cirriculum developed specifically for their clients.</p>
    <button type="button" class="btn btn-primary btn-lg" routerLink="sched"><a>current schedule</a></button>
  </div>
  <div class="sectdiv"></div>
  <div class="service"> <!--UNMANNED AERIAL-->
    <h3>unmanned aerial photography and videography</h3>
    <p>Great Plains Technical brings over 15 years of video and photography experience along with our different UAV platforms that can provide up to 5K video and 20MP photos. A Certified and experienced remote pilot will guide you through the process of how your deliverables will be best achieved for your application.</p>
    <button type="button" class="btn btn-primary btn-lg" routerLink="contact"><a>find out more</a></button>
  </div>
  <div class="sectdiv"></div>
  <div class="service"> <!--UNMANNED AERIAL-->
    <h3>unmanned aerial mapping</h3>
    <p>Great Plains Technical brings over 10 years of ground-based evidence and scene mapping to the sky. A Certified and experienced remote pilot will guide you through the process of how your deliverables will be best achieved for your application.</p>
    <p>Great Plains Technical personnel are not licensed surveyors so we will work alongside a licensed surveyor should your project need the deliverables that can only be approved by surveyors. We also produce non-survey grade products such as property overviews, scaled orthomosaics and 3D models for personal use outside of the use of survey-grade deliverables used for legal documents and proceedings.</p>
    <button type="button" class="btn btn-primary btn-lg" routerLink="contact"><a>find out more</a></button>
  </div>
  <div class="sectdiv"></div>
  <div class="service"> <!-- TECHNICAL INSTRUCTION -->
    <h3>technical instruction</h3>
    <p>With hundreds of hours in the classroom as instructors for applied science and mathematics in the field of crash investigation and reconstruction GPTS partners have learned how to boil the science down to practical and applied knowledge for its students. The current program we are responsible for through the Nebraska Law Enforcement Training Center has been continually developed for over 20 years and we look forward to bringing the field of crash investigation into the future with advanced technical methodologies as well as a strong emphasis in proper scene documentation.</p>
    <button type="button" class="btn btn-primary btn-lg" routerLink="sched"><a>upcoming courses</a></button>
  </div>
  <div class="sectdiv"></div>
  <div class="info">
    <h3>Professional Relationships</h3>
    <div class="info-col">
      <div class="info-cpy">
        <p>GPTS has formed relationships with the following companies and resources to provide our clients with the best possible products and experiences.
        </p>
        <p>Please contact us for referrals or more information. We have provided links to the appropriate services with the respective logos in this section.
        </p>
        <p>We are looking forward to building our business relationships with more companies as our services and client-base expand.</p>
      </div> <!--col 1-->
      <div class="logos">
        <a href="https://leica-geosystems.com/products/laser-scanners/software/ims/leica-map360"><img src="../assets/img/IMS360_logo.png" alt="IMS Map360 logo"></a>
        <a href="http://nletc.nebraska.gov"><img src="../assets/img/nletc_logo.png" alt="NLETC logo"></a>
      </div><!--col 2-->
    </div>
  </div>
  <div class="sectdiv"></div>

</div> <!--content END-->
