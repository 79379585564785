<div class="container-fluid sched">
  <div class="title">
    <h2>Current Schedule</h2>
  </div>
  <div class="row justify-content-center courses"> <!--SCHEDULE INFO-->
    <div class="col-md-12 course">
      <h4>All Courses Listed below are presented by ACTAR Accredited Instructors</h4>
    </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>Intermediate Crash Investigation</h4>
      <h5 class="attention-n">Spring 2023</h5>
      <p class="crs-date"><strong>TBD</strong></p>
      <p class="crs-location">NLETC</p>
      <p class="crs-desc">A 40-hour course with an emphasis on "at-scene" evidence recognition and collection, scene photography, diagramming and an introduction to mathematical formulas and concepts used in advanced crash investigation techniques related to the gathered physical evidence. </p>
    </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>Advanced Crash Investigation</h4>
      <h5 class="attention-n">NEW 2022 DATE</h5>
      <p class="crs-date"><strong>UPDATED -  September 19-23, 2022</strong></p>
      <p class="crs-location">NLETC</p>
      <p class="crs-desc">A 40-hour course with an emphasis on special problems in accident investigations and introduction to vehicle dynamics, time-distance relationships, airborne considerations and real-world practical case analysis.</p>
      <span class="crs-link"><a href="https://ncc.nebraska.gov/event/advanced-crash-investigation-0" target="_blank">NCC Register - ACI Sept 2022</a></span>
    </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>Technical Crash Investigation</h4>
      <h5 class="attention-n">FALL 2022 - 40 HOURS</h5>
      <p class="crs-date"><strong>November 14-18, 2022</strong></p>
      <p class="crs-location">NLETC</p>
      <p class="crs-desc">A 40 hour course where the applied physics of vehicle dynamics will be explored at a deeper level. The students will learn to do vehicle speed determinations using linear momentum solutions and will further build the student's abilities with time/distance calculations and applications. This course will allow to the student to be a proficient on-scene technical investigator to support Reconstructionists in their agencies or regions. It will also prepare them to move on to the Crash Reconstruction curriculum.</p>
      <span class="crs-link"><a href="https://ncc.nebraska.gov/event/technical-crash-investigation" target="_blank">NCC Register - Nov 2022</a></span>
    </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>Traffic Crash Reconstruction</h4>
      <h5 class="attention">2023</h5>
      <p class="crs-date">80 HOURS</p>
      <p class="crs-location">NLETC</p>
      <p class="crs-desc">This is an 80-hour course. Prior to the start of the class, study the materials you received in Intermediate, Advanced, and Technical Crash Investigations; particularly with regard to basic mathematics and algebra. This course is the fourth and final piece in the NLETC crash investigation curriculum. The students will be instructed in specialized applications of momentum as well as completing real-world projects to emphasize all aspects of these courses from Intermediate on up. Further blocks on technology, courtroom considerations, report writing and human factors will round-out the course so that the student has a complete picture of many of the aspects of the crash reconstruction process. Introductions to specialized courses in specific vehicle and crash types (ie. motorcycles, bicycles, commercial vehicles, rollovers, pedestrians, etc) will be given so that the investigator is aware of further courses to expand their knowledge in this ever-evolving field.</p>
      <!-- <span class="crs-link"><a href="https://ncc.nebraska.gov/event/traffic-crash-reconstruction-0" target="_blank">NLETC Register</a></span> -->
    </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>IMS Map360 - LIVE ONLINE COURSE</h4>
      <h5 class="attention">By Request</h5>
      <p class="crs-date"><a href="tel:531-444-9955">Request a new course!</a></p>
      <p class="crs-location">LIVE ONLINE</p>
      <p class="crs-desc">This flexible duration course covers the IMS Map360 Core product use along with importing total station or entering hand measurement data.  If sponsored by Nebraska Highway Safety attendees will receive a copy of the new IMS Map360 Sketch product and the license to install the program on one computer at their respective agency. Students will be polled and course length will be determined for optimal teaching time for the majority of attendees -- <strong>3 to 5 days</strong></p>
      </div> <!--COURSE INFO-->
    <div class="col-md-12 course">
      <h4>Custom Training Request</h4>
      <p class="crs-date">TBD</p>
      <p class="crs-location">Host location</p>
      <p class="crs-desc">If you need training brought on site to maximize your agency training budget please give us a <a href="tel:531-444-9955">call</a> to discuss setting up a training session at a location convenient for your personnel and any surrounding agency personnel you would invite at your discretion.</p>
    </div> <!--COURSE INFO-->
  </div>
  <div class="sectdiv"></div>
</div> <!--courses END-->
