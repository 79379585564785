<div class="deliv-content">
  <div class="card">
    <h2>UAV Deliverables</h2>
    <p>The following content are included in your project cost unless otherwise noted below.</p>

    <h5>Video</h5>
    <ul>
      <li>4K @ 23.976/24/25/29.97/50/59.94p FPS  (<code>[H264].mp4 or .mov / RAW /ProRes</code>)</li>
      <li>5K @ 30 FPS</li>
      <li>12 bit - 100Mbps</li>
      <li>1080/720p 24/25/30/48/50/60</li>
    </ul>
    <h5>Photo</h5>
    <ul>
      <li>JPEG / DNG file output</li>
      <li>20.8 MP still resolution (4/3 format)</li>
      <li>5280 x 3956 (4:3)</li>
      <li>5280 x 2970 (16:9)</li>
      <li>16 MP resolution available upon request</li>
    </ul>
    <h5>Aerial Mapping</h5>
    <ul>
      <li>Point cloud LAS files -- <span>Processing Fee</span> </li>
      <li>Geotiff orthomosaic (flat image of site mapped - georeferenced) -- <span>Processing Fee</span> </li>
      <li>RAW files if customer owns LAS processing software (PIX4D, etc) - No fee</li>
      <li>Additional output files available upon request</li>
    </ul>
    <h5>Professional Videography</h5>
    <ul>
      <li>GPTS has a professional videographer that can work with our remote pilot so that the camera can be controlled   independant of the drone movement. This allows superior flexibility and capture for your project and allows the greatest final product and increased safety during the shoot. Please <a routerLink="../contact">contact us</a> for full details.</li>
    </ul>
  </div>
</div>
