import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  public isCollapsed = {};

//  toggleMenu() {
//     this.isCollapsed = !this.isCollapsed;
//   }
}
