import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contentsched',
  templateUrl: './contentsched.component.html',
  styleUrls: ['./contentsched.component.scss']
})
export class ContentschedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
