<!--The content below is only a placeholder and can be replaced.-->
<app-header>
  <!-- only nav content here -->
</app-header>
<app-jumbo></app-jumbo>
<!-- jumbotron content -->

<router-outlet></router-outlet>

<app-footer></app-footer>
