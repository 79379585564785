<div class="blockpage">
  <div class="bizinfo">
    <div class="lblock">
      <div class="txtblock">
        <h3>Contact Information</h3>
        <h5>Send us an email:</h5>
        <h4><a href="mailto:info@greatplainstech.com">info@greatplainstech.com</a></h4>
        <h5>Or give us a call:</h5>
        <h4><span><a href="tel:531-444-9955">531-444-9955</a></span></h4>
        <p>If you have any questions about our services, setting up a potential job or course just give us a call.</p>
        <p>Advice or estimates are always at no cost to our clients and we look forward to speaking with you.</p>
        <p>Our business information is <a routerLink="../busi">found here</a> but contact us for further information if needed.</p>
      </div>
    </div>
    <div class="rblock">
      <h5>For hosting courses here are some steps that we will take with you:</h5>
      <ul>
        <li>Consider the topic and appropriate instruction environment (indoor or outdoor)</li>
        <li>Consider the pontential number of attendees you will invite and the needed space</li>
        <li>Consider technological needs. We can provide the classroom projector and other materials but will need sufficient outlets and internet access as well as table or desk space for each student</li>
      </ul>
      <h5>For providing drone services please consider the following for your flight request:</h5>
      <ul>
        <li>Is the site near heavy air traffic? We will speak to you about the regulations or potential restrictions we have.</li>
        <li>Will the site be over or near people on the ground?</li>
        <li>What is your goal with the flight? (ie. photos, videos, mapping, 3D/spatial data) Check out our <a routerLink="../deliv">Deliverables</a> page for our available formats with our current equipment</li>
        <li>How much footage do you need? Will this be for marketing or promotional b-roll stock footage or primary footage for your project?</li>
      </ul>
    </div> <!-- rblock -->
  </div> <!-- biz info -->
</div><!-- block page -->
