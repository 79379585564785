<video id="bgvid" playsinline autoplay muted loop>
<!-- WCAG general accessibility recommendation is that media such as background video play through only once. Loop turned on for the purposes of illustration; if removed, the end of the video will fade in the same way created by pressing the "Pause" button  -->
<source src="../../assets/vid/IntersectionLoop(web).mp4" type="video/mp4">
</video>

<div>
  <div class="mainpanel">
    <h1>UAS AERIAL MAPPING & PHOTOGRAPHY</h1>
    <span></span>
    <h1>SOFTWARE & TECHNICAL INSTRUCTION</h1>
  </div>
</div>
