<div class="blockpage">
  <div class="insinfo">
    <div class="lblock">
      <div class="txtblock">
        <h3>Insurance Information:</h3>
        <p>We realize that our clients depend on us to be licensed by the FAA for the drone work that we perform. All active pilots maintain this license and regular flights or training to maintain proficiency with our equipment. We also understand the need in this day and age to maintain the proper level of professional and general liability insurance should something unexpected occur. We can provide prospective clients with our insurance information during the initial stages of our estimate process so that you can be assurred that all interested parties are satisfied.</p>
        <p>Please find FAA UAS information here:</p>
        <p><a href="https://www.faa.gov/uas/faqs/#fwb">Link to FAA</a></p>
      </div>
    </div>
    <div class="rblock">
    </div>
  </div>
</div><!-- blockpage -->
