<div class="content">
  <div class="content-box">
    <div class="service"> <!--ABOUT INFO-->
      <h3>ABOUT OUR COMPANY</h3>
      <p>Great Plains Technical Services, LLC partners have a long history of professional services and development in the field of accident investigation and scene mapping and looks forward to providing the industry with the best quality deliverables from drone-based flight as well as the best technical instruction in the classroom. We have a history and desire to change the way we present our materials to future generations in the software and on-scene investigation aspects of accident investigation and future topics as our capabilities expand. Look <a routerLink="/sched">here</a> for our current schedule of courses hosted at NLETC and check back for courses hosted at other locations in the region.</p>
      <button type="button" class="btn btn-secondary btn-lg"><a routerLink="/sched">current courses</a></button>
      <p></p>
      <p>or</p>
      <button type="button" class="btn btn-secondary btn-lg"><a routerLink="/contact">contact us</a></button>
    </div> <!-- service -->
  </div> <!-- content-box -->
</div> <!--content END-->
