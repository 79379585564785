<div class="blockpage">
  <div class="bizinfo">
    <div class="lblock">
      <div class="txtblock">
        <h5>Business Information</h5>
        <p><span class="info_title">Email:</span><span><a href="mailto:info@greatplainstech.com">info@greatplainstech.com</a></span></p>
        <p><span class="info_title">Phone:</span><span><a href="tel:531-444-9955">531.444.9955</a></span></p>
        <p><span class="info_title">Billing:</span><span><a href="mailto:billing@greatplainstech.com">billing@greatplainstech.com</a></span></p>
        <p><span class="info_title">TaxID:</span><span>Please call or request W9 via email</span></p>
        <p><span class="info_title">Address:</span>
          <span class="addy">
            <p>Great Plains Technical Services, LLC</p>
            <p>19714 Woolworth Avenue</p>
            <p>Omaha, NE 68130</p>
          </span>
        </p>
      </div>
    </div>
    <div class="rblock">
      <!-- photo -->
    </div> <!-- rblock -->
  </div> <!-- biz info -->
</div><!-- block page -->
