<footer class='bg-dark'>
  <div class="container-fluid">
    <div class="row ft-content">
      <div class="col-sm-6 quote-block">
        <div class="qt-content">
          <h3>Professional Drone Services.</h3>
          <h4>Technical Field and Software Training.</h4>
          <img src="../assets/img/gpts-full_dkbg.png" alt="full company logo"/>
          <h2><span><a href="tel:531-444-9955">531.444.9955</a></span></h2>
        </div>

      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-sm-3 ft-nav">
            <h5>sUAS Project</h5>
            <a routerLink="contact">Contact Us</a>
            <a routerLink="deliv">Deliverables</a>
            <a routerLink="insur">Insurance</a>
          </div>
          <div class="col-sm-3 ft-nav">
            <h5>Company</h5>
            <a routerLink="busi">Tax/Business Info</a>
            <a routerLink="privacy">Privacy Policy</a>
          </div>
          <div class="col-sm-3 ft-nav">
            <h5>Site</h5>
            <a routerLink="sched">Courses</a>
            <a routerLink="about">About</a>
            <!-- <a href="#">Online Tutorials</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="legal text-center">
    <p><span>2022</span> Great Plains Technical Services, LLC</p>
    <p><span> Design by </span><span class="built"><a href="https://www.pixelengineering.co">Pixel Engineering Co</a></span></p>
  </div> <!--LEGAL-->
</footer>
