import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contentsuas',
  templateUrl: './contentsuas.component.html',
  styleUrls: ['./contentsuas.component.scss']
})
export class ContentsuasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
