<div class="privcontent">
  <h5>Great Plains Technical Services, LLC (otherwise "Great Plains Technical") Complete Terms and Conditions.</h5>

  <p>Please read completely and do not continue until you completely understand and agree to all the following terms and conditions set forth.</p>

  <p>By using this website as a tool for viewing information, users, persons and or companies hereby agree to the following terms and conditions.</p>

  <h5>Trademark Copyright Notice.</h5>
  <p>No person or company may use this site for any other reason other than for information and contact. All materials appearing on this site, including pictures, text, diagrams, instructions, assembly, links, videos, logos, graphics, images, are sole property of Great Plains Technical Services, LLC. No person or company may copy, modify, reproduce, download, upload, republish, distribute or transmit any material off this site without our prior written permission. All rights for such acts are exclusively reserved by Great Plains Technical Services, LLC. Any unauthorized use of the materials on this website, any use of Great Plains Technical Services, LLC Copyrights may violate trademark and copyright laws, and will result in civil penalties and or criminal charges.</p>
  <h5>Website and Email Communication Privacy Notice.</h5>
  <p>Any messages sent though this website, and/or through any Great Plains Technical Services email account, is strictly confidential and only intended for the intended recipients viewing and information.</p>
  <p>The information contained in such communications and/or emails (which mean the narrative, attachments, and links) is intended only for the personal and confidential use of the recipient(s) specifically named therein. To the extent that the information is confidential to Great Plains Technical Services, LLC, it is not to be disclosed to any others (without prior written consent from Great Plains Technical Services, LLC), and in no event to the general public. If the reader of such email or message is not the intended recipient; the reader is notified that you have received this email or message in error and that any review, distribution or copying of this email or message is prohibited. If you have received this email or message in error please notify the sender immediately by telephone at 402-750-6433 or by reply email.</p>
  <h5>Links</h5>
  <p>This site may contain links to other sites on the Internet that are owned and operated by third parties. You acknowledge we are not responsible for the operation of or content located on or through any other site. Great Plains Technical has no control over these linked sites, all of which have separate privacy and data collection practices, independent of Great Plains Technical. These linked sites are only for your convenience and therefore if you to access them, you agree that you are doing so at your own risk.</p>
  <h5>Copyright &copy; 2017 - 2021 ALL RIGHTS RESERVED</h5>

</div>
